import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { AccountService } from '../account.service';
import { HostnameService } from '../hostname.service';

export const VAN_PHONG_CODE = '103';

@Injectable({ providedIn: 'root' })
export class AnnualReportService {
    constructor(private httpClient: HttpClient, private accountService: AccountService, private hostnameService: HostnameService) {}

    async fetchFullMetadata(nam: string, loai_bao_cao: string, ma_don_vi: string = '') {
        return await new Promise<any>((resolve, reject) => {
            let final_metadata: any = this.accountService.getMetadata();
            if (!ma_don_vi || ma_don_vi == '') ma_don_vi = final_metadata.ma_don_vi;

            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI + '/api/v1/read/annual-report/get-metadata',
                    {
                        metadata: {
                            ma_don_vi: final_metadata.ma_don_vi,
                            loai_bao_cao: loai_bao_cao,
                        },
                        nam: nam,
                        ma_don_vi: ma_don_vi,
                    },
                    {
                        headers: { apikey: this.accountService.apikey },
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async editMetadata(metadata: any) {
        return await new Promise<any>((resolve, reject) => {
            let final_metadata: any = this.accountService.getMetadata();
            // debugger;
            metadata = {
                ...metadata,
                ma_don_vi: final_metadata.ma_don_vi,
                mat_khau_don_vi: final_metadata.mat_khau_don_vi,
            };
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI + '/api/v1/staging/annual-report/metadata/update-metadata',
                    {
                        metadata: metadata,
                    },
                    {
                        headers: { apikey: this.accountService.apikey },
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    extractReport(code: string, nam: string, loai_bao_cao: string, docType: string) {
        let metadata: any = this.accountService.getMetadata();
        metadata.mat_khau_don_vi = '';
        metadata.nam = nam;
        metadata.loai_bao_cao = loai_bao_cao;
        metadata.ma_don_vi_bao_cao = code;

        return this.httpClient
            .post<any>(
                this.hostnameService.VnuisAPI + '/api/v1/read/annual-report/extract/' + docType + '/ttkdgd',
                {
                    metadata: metadata,
                    ma_don_vi: metadata.ma_don_vi,
                },
                {
                    headers: { apikey: this.accountService.apikey },
                }
            )
            .toPromise();
    }

    async addFile(metadata: any, file: File) {
        let final_metadata: any = this.accountService.getMetadata();
        metadata.ma_don_vi = final_metadata.ma_don_vi;
        metadata.mat_khau_don_vi = '';

        let formData: FormData = new FormData();
        formData.set('metadata', JSON.stringify(metadata));
        formData.set('file', file);

        return this.httpClient
            .post<any>(this.hostnameService.VnuisAPI + '/api/v1/staging/annual-report/add-file/ttkdgd', formData, {
                headers: { apikey: this.accountService.apikey },
            })
            .toPromise();
    }

    async downloadTemplate(metadata: any) {
        let final_metadata: any = this.accountService.getMetadata();
        metadata.ma_don_vi = final_metadata.ma_don_vi;
        metadata.mat_khau_don_vi = '';

        return this.httpClient
            .post<any>(
                this.hostnameService.VnuisAPI + '/api/v1/read/annual-report/get-template/ttkdgd',
                {
                    metadata: metadata,
                },
                {
                    headers: { apikey: this.accountService.apikey },
                }
            )
            .toPromise();
    }
}
