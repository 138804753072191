import { Component, OnInit } from '@angular/core';
import { GeneralDataService } from './providers/general-data.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
    constructor(private generalDataService: GeneralDataService) {}
    ngOnInit(): void {
        this.generalDataService.fetch();
    }
}
