import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { AccountService } from './account.service';
import { HostnameService } from './hostname.service';

export interface Learner {
    ma_don_vi: string;
    ma_sv: string;
    email: string;
    phone: string;
    ho_ten_dem: string;
    ten: string;
    ngay_sinh: string;
    gioi_tinh: string;
    quoc_tich: string;
    dan_toc: string;
    ton_giao: string;
    xuat_than: string;
    doi_tuong: string;
    khu_vuc: string;
    vung_mien: string;
    chinh_sach: string;
    dia_chi_lien_he: string;
    so_the_bhyt: string;
    don_vi: string;
    khoa: string;
    lop_sh: string;
    he_dao_tao: string;
    khoa_hoc: string;
    nganh_hoc: string;
    chuyen_nganh: string;
    tinh_trang: string;
    ngay_vao_doan: string;
    ngay_vao_dang: string;
    ho_ten_cha: string;
    ho_ten_me: string;
    ghi_chu: string;

    noi_sinh: any;
    cmnd_cccd: any;
    que_quan: any;
    dia_chi_thuong_tru: any;
    ky_tuc_xa: any;

    diem_hoc_tap: any[];
    diem_ren_luyen: any[];
    khen_thuong: any[];
    ky_luat: any[];
    hoc_bong: any[];
}

@Injectable({ providedIn: 'root' })
export class LearnerService {
    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService
    ) {}

    // async fetchLearner() {
    //     return await new Promise<Learner[]>((resolve, reject) => {
    //         this.httpClient
    //             .post<Learner[]>(this.hostnameService.VnuisAPI + '/api/v1/read/learner/get-all', {
    //                 apikey: this.accountService.apikey,
    //             })
    //             .subscribe((data) => {
    //                 data.sort((e1: any, e2: any) => {
    //                     if (e1.data.ma_sv < e2.data.ma_sv) return -1;
    //                     if (e1.data.ma_sv > e2.data.ma_sv) return 1;
    //                     return 0;
    //                 });
    //                 resolve(data);
    //             });
    //     });
    // }

    async fetchLearnerByMetadata() {
        return await new Promise<Learner[]>((resolve, reject) => {
            let final_metadata: any = this.accountService.getMetadata();

            this.httpClient
                .post<Learner[]>(this.hostnameService.VnuisAPI + '/api/v1/read/learner/get-all-by-metadata', {
                    apikey: this.accountService.apikey,
                    metadata: final_metadata,
                })
                .subscribe((data) => {
                    data.sort((e1: any, e2: any) => {
                        if (e1.data.ma_sv < e2.data.ma_sv) return -1;
                        if (e1.data.ma_sv > e2.data.ma_sv) return 1;
                        return 0;
                    });
                    resolve(data);
                });
        });
    }

    async fetchLearnerPartByMetadata() {
        return await new Promise<Learner[]>((resolve, reject) => {
            let final_metadata: any = this.accountService.getMetadata();

            this.httpClient
                .post<Learner[]>(this.hostnameService.VnuisAPI + '/api/v1/read/learner/get-part-by-metadata', {
                    apikey: this.accountService.apikey,
                    metadata: final_metadata,
                })
                .subscribe((data) => {
                    data.sort((e1: any, e2: any) => {
                        if (e1.data.ma_sv < e2.data.ma_sv) return -1;
                        if (e1.data.ma_sv > e2.data.ma_sv) return 1;
                        return 0;
                    });
                    resolve(data);
                });
        });
    }

    async deleteById(id: string) {
        let final_metadata: any = this.accountService.getMetadata();

        return await new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(this.hostnameService.VnuisAPI + '/api/v1/staging/learner/delete', {
                    id: id,
                    apikey: this.accountService.apikey,
                    metadata: final_metadata,
                })
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    extractLearnerList() {
        let url =
            this.hostnameService.VnuisAPI +
            '/api/v1/read/learner/extract-list.xlsx?apikey=' +
            this.accountService.apikey;
        let final_metadata: any = this.accountService.getMetadata();
        url += '&metadata=' + JSON.stringify(final_metadata);

        // var a = document.createElement('a');
        // a.href = url;
        // a.setAttribute('download', 'output.xlsx');
        // a.click();

        this.httpClient.get(url).subscribe((data) => {});
    }

    downloadTemplate() {
        let url =
            this.hostnameService.VnuisAPI +
            '/api/v1/read/learner/download-template.xlsx?apikey=' +
            this.accountService.apikey;
        var a = document.createElement('a');
        a.href = url;
        a.setAttribute('download', 'template.xlsx');
        a.click();
    }

    async addMultiple(learners: Learner[], metadata: any) {
        let final_metadata: any = this.accountService.getMetadata();
        final_metadata = { ...metadata, ...final_metadata };

        return await new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI +
                        '/api/v1/staging/learner/add-multiple?apikey=' +
                        this.accountService.apikey,
                    {
                        apikey: this.accountService.apikey,
                        metadata: final_metadata,
                        data: learners,
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async count() {
        return await new Promise<any>((resolve, reject) => {
            let final_metadata: any = this.accountService.getMetadata();
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI + '/api/v1/read/learner/count',
                    {
                        metadata: final_metadata,
                    },
                    {
                        headers: {
                            apikey: this.accountService.apikey,
                        },
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }
}
