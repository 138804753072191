import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { createMapingDLHC } from '../utils/general-data.util';
import { AccountService } from './account.service';
import { HostnameService } from './hostname.service';
import { OrgDataService } from './org-data.service';

@Injectable({ providedIn: 'root' })
export class GeneralDataService {
    private _data: any;
    private _dataSub: Subject<any> = new Subject<any>();

    public get data(): any {
        return this._data;
    }
    public set data(value: any) {
        this._data = value;
        this._dataSub.next(this._data);
    }
    public get dataSub(): Subject<any> {
        return this._dataSub;
    }

    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService,
        private orgDataService: OrgDataService
    ) {}

    async fetchAll() {
        return this.httpClient
            .get<any>(this.hostnameService.VnuisAPI + '/api/v1/read/general-data/get-all', {
                headers: {
                    apikey: this.accountService.apikey,
                },
            })
            .toPromise();
    }

    async fetchAllByType(type: string) {
        return this.httpClient
            .get<any>(this.hostnameService.VnuisAPI + '/api/v1/read/general-data/get-all-by-type?type=' + type, {
                headers: {
                    apikey: this.accountService.apikey,
                },
            })
            .toPromise();
    }

    async fetch() {
        let data = {};

        let results = await Promise.all([
            this.fetchAll(),
            this.orgDataService.getAllByParrentCodes(['3', '4', '5']),
            this.httpClient
                .get<any>(this.hostnameService.VnuisAPI + '/api/v1/read/general-data/get-all-dlhc', {
                    headers: {
                        apikey: this.accountService.apikey,
                    },
                })
                .toPromise(),
        ]);

        // general data
        let generalData = results[0];
        if (generalData != 'FAIL') data = { ...data, ...generalData };

        // don vi cong tac
        let donViCongTac = results[1];
        if (donViCongTac != 'FAIL') {
            donViCongTac = donViCongTac.sort((a: any, b: any) => {
                let code_a = parseInt(a.org_code);
                let code_b = parseInt(b.org_code);
                return code_a - code_b;
            });
            data = { ...data, don_vi_cong_tac: donViCongTac };
        }

        //du lieu dia li hanh chinh
        let dlhc: any = results[2];
        if (dlhc != 'FAIL') {

            data = {
                ...data,
                dlhc_phuong_xa: createMapingDLHC(dlhc.phuong_xa),
                dlhc_quan_huyen: createMapingDLHC(dlhc.quan_huyen),
                dlhc_tinh_thanh: createMapingDLHC(dlhc.tinh_thanh),
                code_px_from_code_tt_qh: dlhc.code_px_from_code_tt_qh,
                code_qh_from_code_tt: dlhc.code_qh_from_code_tt
            };
        }

        this.data = data;
    }

    getDataByCode(ma: string, type: string, mainKey: string = 'ma') {
        if (type in this._data) {
            let data = this._data[type];
            for (let row of data) if (row[mainKey] == ma) return row;
        }

        return undefined;
    }

    getByType(type: string) {
        return this._data && this._data[type] ? this._data[type] : [];
    }
}
