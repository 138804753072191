import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { AccountService } from './account.service';
import { HostnameService } from './hostname.service';

@Injectable({ providedIn: 'root' })
export class OrganizationService {
    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService
    ) {}

    async getAllbyParentCode(ma_don_vi: string = this.accountService.getMetadata().ma_don_vi) {
        return await new Promise<any[]>((resolve, reject) => {
            this.httpClient
                .get<any[]>(
                    this.hostnameService.VnuisAPI +
                        '/api/v1/read/org-data/get-all-by-parent_code?parent_code=' +
                        ma_don_vi,
                    {
                        headers: {
                            apikey: this.accountService.apikey,
                        },
                    }
                )
                .subscribe((data: any) => {
                    data.sort((a: any, b: any) => {
                        if (String(a['org_code']) > String(b['org_code'])) return 1;
                        if (String(a['org_code']) < String(b['org_code'])) return -1;
                        return 0;
                    });
                    resolve(data);
                });
        });
    }

    async add(data: any[]) {
        let final_metadata = this.accountService.getMetadata();
        for (let row of data) row['parrent_code'] = final_metadata.ma_don_vi;

        return await new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI + '/api/v1/staging/org-data/add',
                    {
                        data: data,
                    },
                    {
                        headers: {
                            apikey: this.accountService.apikey,
                        },
                    }
                )
                .subscribe((data: any) => {
                    resolve(data);
                });
        });
    }

    async edit(data: any[]) {
        let final_metadata = this.accountService.getMetadata();
        for (let row of data) row['parrent_code'] = final_metadata.ma_don_vi;

        return await new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI + '/api/v1/staging/org-data/edit',
                    {
                        data: data,
                    },
                    {
                        headers: {
                            apikey: this.accountService.apikey,
                        },
                    }
                )
                .subscribe((data: any) => {
                    resolve(data);
                });
        });
    }

    async delete(org_code: string) {
        return await new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI + '/api/v1/staging/org-data/delete',
                    {
                        data: { org_code: org_code },
                    },
                    {
                        headers: {
                            apikey: this.accountService.apikey,
                        },
                    }
                )
                .subscribe((data: any) => {
                    resolve(data);
                });
        });
    }
}
