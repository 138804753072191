import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { AccountService } from '../account.service';
import { HostnameService } from '../hostname.service';

@Injectable({ providedIn: 'root' })
export class MFilesService {
    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService
    ) {}

    async fetch(nam: string, loai_bao_cao: string, ma_don_vi: string = '') {
        return await new Promise<any>((resolve, reject) => {
            let final_metadata: any = this.accountService.getMetadata();
            if (!ma_don_vi || ma_don_vi == '') ma_don_vi = final_metadata.ma_don_vi;
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI + '/api/v1/read/report-staff-organization/files/get',
                    {
                        metadata: {
                            ma_don_vi: final_metadata.ma_don_vi,
                            mat_khau_don_vi: final_metadata.mat_khau_don_vi,
                            loai_bao_cao: loai_bao_cao,
                        },
                        nam: nam,
                        ma_don_vi: ma_don_vi,
                    },
                    {
                        headers: { apikey: this.accountService.apikey },
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async add(metadata: any, file: any, display_name: any) {
        return await new Promise<any>((resolve, reject) => {
            let final_metadata: any = this.accountService.getMetadata();
            metadata.ma_don_vi = final_metadata.ma_don_vi;
            metadata.mat_khau_don_vi = final_metadata.mat_khau_don_vi;

            let formData = new FormData();
            formData.append('file', file);
            formData.append('metadata', JSON.stringify(metadata));
            formData.append('display_name', display_name);

            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI + '/api/v1/staging/report-staff-organization/files/add',
                    formData,
                    {
                        headers: { apikey: this.accountService.apikey },
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async delete(nam: string, loai_bao_cao: string, filename: string) {
        return await new Promise<any>((resolve, reject) => {
            let final_metadata: any = this.accountService.getMetadata();
            let metadata = {
                ma_don_vi: final_metadata.ma_don_vi,
                mat_khau_don_vi: final_metadata.mat_khau_don_vi,
                nam: nam,
                loai_bao_cao: loai_bao_cao,
            };

            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI + '/api/v1/staging/report-staff-organization/files/delete',
                    {
                        metadata: metadata,
                        filename: filename,
                    },
                    {
                        headers: { apikey: this.accountService.apikey },
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }
}
