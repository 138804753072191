import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { AuthGuard } from './helper/auth.guard';
import { UserInspectorGuard } from './helper/user-inspector.guard';
import { UserLeaderGuard } from './helper/user-leader.guard';

export const AppRoutes: Routes = [
    {
        path: '',
        data: { everyRoles: ['admin'] },
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                redirectTo: 'user/dashboards',
                pathMatch: 'full',
            },
            {
                path: 'user',
                loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
            },
        ],
    },
    {
        path: '',
        component: AppBlankComponent,
        children: [
            {
                path: 'authentication',
                loadChildren: () => import('./authentication/authentication.module').then((m) => m.AuthenticationModule),
            },
        ],
    },
    {
        path: '**',
        redirectTo: 'authentication/404',
    },
];
