import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { AccountService } from '../account.service';
import { HostnameService } from '../hostname.service';

@Injectable({ providedIn: 'root' })
export class ReportStaffOrganizationService {
    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService
    ) {}

    async fetchFullMetadata(nam: string, loai_bao_cao: string, ma_don_vi: string = '') {
        return await new Promise<any>((resolve, reject) => {
            let final_metadata: any = this.accountService.getMetadata();
            if (!ma_don_vi || ma_don_vi == '') ma_don_vi = final_metadata.ma_don_vi;
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI + '/api/v1/read/report-staff-organization/get-metadata',
                    {
                        metadata: {
                            ma_don_vi: final_metadata.ma_don_vi,
                            mat_khau_don_vi: final_metadata.mat_khau_don_vi,
                            loai_bao_cao: loai_bao_cao,
                        },
                        nam: nam,
                        ma_don_vi: ma_don_vi,
                    },
                    {
                        headers: { apikey: this.accountService.apikey },
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async editMetadata(metadata: any) {
        return await new Promise<any>((resolve, reject) => {
            let final_metadata: any = this.accountService.getMetadata();
            // debugger;
            metadata = {
                ...metadata,
                ma_don_vi: final_metadata.ma_don_vi,
                mat_khau_don_vi: final_metadata.mat_khau_don_vi,
            };
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI +
                        '/api/v1/staging/report-staff-organization/metadata/update-metadata',
                    {
                        metadata: metadata,
                    },
                    {
                        headers: { apikey: this.accountService.apikey },
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }
}
