import { GeneralDataService } from '../providers/general-data.service';

export function codeToData(
    generalDataService: GeneralDataService,
    code: string,
    type: string,
    mainKey: string = 'ma',
    displayKey: string = 'ten'
) {
    let row = generalDataService.getDataByCode(code, type, mainKey);
    if (!row || !row[displayKey]) return '';
    return row[displayKey];
}

export function createMapingDLHC(dlhc: any) {
    let result: any = [];
    for (let key of Object.keys(dlhc)) result.push({ ma: key, ten: dlhc[key] });
    result = result.sort((a: any, b: any) => {
        if (a.ma > b.ma) return 1;
        if (a.ma < b.ma) return -1;
        return 0;
    });
    return result;
}
